import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-container" }

import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import { Patient } from '@/models/Patient';
import { SurveyType } from '@/models/Survey';
import router from '@/router';
import { patientService } from '@/services/PatientService';
import { ServiceError } from '@/services/util/ServiceError';
import { useProfileStore } from '@/store/profile.module';
import { useToast } from 'primevue/usetoast';
import { onMounted, PropType, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientConfiguration',
  props: {
  patient: {
    type: Object as PropType<Patient>,
    required: true,
  },
},
  emits: ['patient-changed'],
  setup(__props) {



const props = __props;

const route = useRoute();
const { t } = useI18n();
const storeProfile = useProfileStore();

const patientId = Array.isArray(route.params.patientId) ? route.params.patientId[0] : route.params.patientId;

const patient = ref<Patient>();
const isAdminUser = computed(() => storeProfile.isAdmin);
const toast = useToast();
const tabsMenu = ref([
  { label: t(`configuration.patientData`), to: 'patientData' },
  { label: t(`configuration.associatedSpecialists`), to: 'specialists' },
  { label: t(`configuration.patientAccountState`), to: 'patientAccountState' },
]);
const surveyCgmInitial = ref();
const loadingSurvey = ref(true);
const loadingDelete = ref(false);

const showDeleteDialog = ref(false);
const showHasMedicalTestActiveDialog = ref(false);

const fetchCgmInitialSurvey = async () => {
  const result = await patientService.findSurvey(props.patient.id, SurveyType.CGM_INITIAL);
  loadingSurvey.value = false;
  if (!(result instanceof ServiceError)) {
    surveyCgmInitial.value = result;
  }
};

onMounted(async () => {
  await fetchCgmInitialSurvey();
  await loadPatient();
});

const onDeletePatientClick = () => {
  showDeleteDialog.value = true;
};

const onDelete = async () => {
  try {
    loadingDelete.value = true;
    const dietDayUpdated = await patientService.delete(patientId);
    if (dietDayUpdated instanceof ServiceError) {
      if (dietDayUpdated.code === 'PATIENT_CAN_NOT_BE_DELETED') {
        showHasMedicalTestActiveDialog.value = true;
      }

      toast.add({
        severity: 'error',
        summary: `${t('messages.notifications.errorDeletePatient')} ${t('messages.notifications.tryLater')}`,
        life: 3000,
      });
    } else {
      toast.add({
        severity: 'success',
        summary: `${t('messages.notifications.successDeletePatient')}`,
        life: 3000,
      });
      router.push('/');
    }
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: `${t('messages.notifications.errorDeletePatient')} ${t('messages.notifications.tryLater')}`,
      life: 3000,
    });
  }
  loadingDelete.value = false;
  showDeleteDialog.value = false;
};

const loadPatient = async () => {
  const result = await patientService.find(patientId);
  if (!(result instanceof ServiceError)) {
    patient.value = result;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (loadingSurvey.value)
      ? (_openBlock(), _createBlock(LoadingComponent, {
          key: 0,
          "spinner-color": "lavender-700"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!loadingSurvey.value)
            ? (_openBlock(), _createBlock(HeadingComponent, {
                key: 0,
                title: _ctx.$t(_unref(route).meta.breadcrumb ? _unref(route).meta.breadcrumb[0].label : ''),
                "heading-icon": "icon-settings",
                description: _ctx.$t('personData.description'),
                tabs: tabsMenu.value
              }, {
                right: _withCtx(() => [
                  _createVNode(_component_Button, {
                    class: "p-button p-button-icon p-button-secondary p-button-delete-patient",
                    disabled: !isAdminUser.value || loadingDelete.value,
                    onClick: onDeletePatientClick
                  }, {
                    default: _withCtx(() => [
                      _cache[4] || (_cache[4] = _createElementVNode("i", {
                        class: "icon-trash_full",
                        "aria-hidden": "true"
                      }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('patient.deletePatient')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }, 8, ["title", "description", "tabs"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_view)
          ])
        ], 64)),
    _createVNode(_component_Dialog, {
      visible: showDeleteDialog.value,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((showDeleteDialog).value = $event)),
      header: _ctx.$t('patient.deletePatient'),
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.cancel'),
          class: "p-button p-component p-button-secondary p-button-medium",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showDeleteDialog.value = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('common.delete'),
          class: "p-button p-component p-button-secondary p-button-medium delete-patient-confirm-button",
          loading: loadingDelete.value,
          onClick: onDelete
        }, null, 8, ["label", "loading"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('patient.sureToDelete', { name: patient.value.name, surname: patient.value.surname })), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('patient.deleteInfoMessage')) + ". " + _toDisplayString(_ctx.$t('messages.actionNotUndone')), 1)
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_component_Dialog, {
      visible: showHasMedicalTestActiveDialog.value,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((showHasMedicalTestActiveDialog).value = $event)),
      header: _ctx.$t('patient.cannotDeletePatient'),
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.$t('common.accept'),
          class: "p-button p-component p-button-primary p-button-medium",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (showHasMedicalTestActiveDialog.value = false))
        }, null, 8, ["label"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('patient.notDeleteHasMedicalTestActive')), 1)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})