import { Genders } from './Genders';

enum SurveyType {
  CGM_INITIAL = 'CGM_INITIAL',
}

enum BodyShapes {
  APPLE_SHAPE = 'APPLE_SHAPE',
  PEAR_SHAPE = 'PEAR_SHAPE',
  HOURGLASS_SHAPE = 'HOURGLASS_SHAPE',
  RECTANGULAR_SHAPE = 'RECTANGULAR_SHAPE',
}

enum Diseases {
  OBESITY = 'OBESITY',
  DIABETES = 'DIABETES',
  DYSLIPIDEMIA = 'DYSLIPIDEMIA',
  THYROID_DISORDER = 'THYROID_DISORDER',
  LIVER_DISORDER = 'LIVER_DISORDER',
  CARDIOVASCULAR_DISORDER = 'CARDIOVASCULAR_DISORDER',
  GASTROINTESTINAL_DISORDER = 'GASTROINTESTINAL_DISORDER',
  INFLAMMATORY_DISORDER = 'INFLAMMATORY_DISORDER',
  AUTOIMMUNE_DISEASE = 'AUTOIMMUNE_DISEASE',
  PCOS = 'PCOS',
  MENTAL_HEALTH = 'MENTAL_HEALTH',
  MIGRAINES = 'MIGRAINES',
  BIORHYTHMS = 'BIORHYTHMS',
  OTHER = 'OTHER',
}

enum AbsShapes {
  FLAT_ABS = 'FLAT_ABS',
  SOME_FAT_ABS = 'SOME_FAT_ABS',
  BELLY_ABS = 'BELLY_ABS',
}

enum Complexion {
  VERY_OBESE = 'VERY_OBESE',
  OBESE = 'OBESE',
  SOME_FAT = 'SOME_FAT',
  SKINNY = 'SKINNY',
  TONED = 'TONED',
  MUSCLED = 'MUSCLED',
  VERY_MUSCLED = 'VERY_MUSCLED',
}

enum Objectives {
  WEIGHT_LOSS = 'WEIGHT_LOSS',
  WEIGHT_GAIN = 'WEIGHT_GAIN',
  DIGESTIVE_IMPROVEMENT = 'DIGESTIVE_IMPROVEMENT',
  ENERGY_IMPROVEMENT = 'ENERGY_IMPROVEMENT',
}

enum WeightQuantity {
  TWO_TO_FIVE = 'TWO_TO_FIVE',
  FIVE_TO_TEN = 'FIVE_TO_TEN',
  MORE_THAN_TEN = 'MORE_THAN_TEN',
}

enum SportStrengthDays {
  ZERO_DAYS = 'ZERO_DAYS',
  FROM_ONE_TO_TWO_DAYS = 'FROM_ONE_TO_TWO_DAYS',
  FROM_THREE_TO_FOUR_DAYS = 'FROM_THREE_TO_FOUR_DAYS',
  MORE_THAN_FIVE_DAYS = 'MORE_THAN_FIVE_DAYS',
}

enum SportCardioDays {
  ZERO_DAYS = 'ZERO_DAYS',
  FROM_ONE_TO_TWO_DAYS = 'FROM_ONE_TO_TWO_DAYS',
  FROM_THREE_TO_FOUR_DAYS = 'FROM_THREE_TO_FOUR_DAYS',
  MORE_THAN_FIVE_DAYS = 'MORE_THAN_FIVE_DAYS',
}

enum SportHourPerDay {
  ONE_TO_TWO = 'ONE_TO_TWO',
  MORE_THAN_TWO = 'MORE_THAN_TWO',
}

enum Diets {
  KETO_DIET = 'KETO_DIET',
  LOW_CARB_DIET = 'LOW_CARB_DIET',
  VEGETARIAN_DIET = 'VEGETARIAN_DIET',
  VEGAN_DIET = 'VEGAN_DIET',
  PALEO_DIET = 'PALEO_DIET',
  PROTEIN_DIET = 'PROTEIN_DIET',
  HIGH_CARB_DIET = 'HIGH_CARB_DIET',
  MEDITERRANEAN_DIET = 'MEDITERRANEAN_DIET',
}

enum NumberOfMeals {
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  MORE_THAN_THREE = 'MORE_THAN_THREE',
}

enum DietSnacks {
  NATURAL_FOOD = 'NATURAL_FOOD',
  PROCESSED_FOOD = 'PROCESSED_FOOD',
}

enum FastHours {
  FROM_TEN_TO_TWELVE = 'FROM_TEN_TO_TWELVE',
  FROM_THIRTEEN_TO_SIXTEEN = 'FROM_THIRTEEN_TO_SIXTEEN',
  MORE_THAN_SIXTEEN = 'MORE_THAN_SIXTEEN',
  OMAD = 'OMAD',
}

enum DigestiveProblems {
  CONSTIPATION = 'CONSTIPATION',
  GASES = 'GASES',
  ABDOMINAL_INFLAMMATION = 'ABDOMINAL_INFLAMMATION',
  DIARRHEA = 'DIARRHEA',
}

enum WaterQuantity {
  LESS_THAN_ONE_LITRE = 'LESS_THAN_ONE_LITRE',
  FROM_ONE_TO_TWO_LITRES = 'FROM_ONE_TO_TWO_LITRES',
  MORE_THAN_TWO_LITRES = 'MORE_THAN_TWO_LITRES',
}

enum DayToDay {
  SEATED_WORK = 'SEATED_WORK',
  ON_MY_FEET = 'ON_MY_FEET',
  AT_HOME = 'AT_HOME',
  PHYSICAL_ACTIVITY = 'PHYSICAL_ACTIVITY',
}

enum DayToDayActivity {
  SEDENTARY = 'SEDENTARY',
  SOMEHOW_ACTIVE = 'SOMEHOW_ACTIVE',
  VERY_ACTIVE = 'VERY_ACTIVE',
  GREAT_PHYSICAL_EFFORT = 'GREAT_PHYSICAL_EFFORT',
}

enum DayToDaySmoke {
  LESS_THAN_TEN = 'LESS_THAN_TEN',
  FROM_TEN_TO_TWENTY = 'FROM_TEN_TO_TWENTY',
  MORE_THAN_TWENTY = 'MORE_THAN_TWENTY',
}

enum RestHours {
  LESS_THAN_FIVE_HOURS = 'LESS_THAN_FIVE_HOURS',
  FROM_FIVE_TO_SEVEN_HOURS = 'FROM_FIVE_TO_SEVEN_HOURS',
  MORE_THAN_SEVEN_HOURS = 'MORE_THAN_SEVEN_HOURS',
}

enum RestDayEnergy {
  SO_SO = 'SO_SO',
  VERY_TIRED = 'VERY_TIRED',
  TIRED_IN_THE_MORNING = 'TIRED_IN_THE_MORNING',
  TIRED_IN_THE_AFTERNOON = 'TIRED_IN_THE_AFTERNOON',
}

enum RestPatterns {
  WAKE_UP_DURING_NIGHT = 'WAKE_UP_DURING_NIGHT',
  SLEEP_THROUGH_NIGHT = 'SLEEP_THROUGH_NIGHT',
  HARD_TO_FALL_ASLEEP = 'HARD_TO_FALL_ASLEEP',
}

enum DrinksPerDay {
  NONE = 'NONE',
  ONE = 'ONE',
  FROM_TWO_TO_THREE = 'FROM_TWO_TO_THREE',
  MORE_THAN_THREE = 'MORE_THAN_THREE',
}

interface Survey {
  id: SurveyType;
  answers: any;
}

class SurveyCgmInitialFormDto {
  id: SurveyType;
  disease: string;
  diseaseDescription: string[];
  diseaseFamily: string;
  diseaseFamilyDescription: string;
  drug: string;
  drugDescription: string;
  age: string;
  birthDate: any;
  gender: string;
  height: string;
  weight: string;
  bodyShape: string;
  absShape: string;
  complexion: string;
  fatPercentage: string;
  musclePercentage: string;
  healzObjectiveDescription: string;
  weightLoseQuantity: string;
  weightLoseDietTested: string;
  weightGainQuantity: string;
  weightGainDietTested: string;
  healzObjective: string;
  sport: string;
  sportHourPerDay: string;
  sportDayTime: string;
  sportStrengthDays: string;
  sportCardioDays: string;
  currentDiet: string;
  currentDietDescription: string;
  currentDietNumberOfMeals: string;
  currentDietSnack: string;
  currentDietSnackDescription: string;
  currentDietFast: string;
  currentDietFastHours: string;
  currentDietDigestiveProblems: string;
  currentDietDigestiveProblemsDescription: string[];
  currentDietSupplement: string;
  currentDietSupplementDescription: string;
  currentDietAllergy: string;
  currentDietAllergyDescription: string;
  currentDietWaterQuantity: string;
  dayToDay: string;
  dayToDayActivity: string;
  dayToDaySmoker: string;
  dayToDaySmokerQuantity: string;
  restHours: string;
  restWakeUpTired: string;
  restDayEnergy: string;
  restPattern: string;
  restSupplement: string;
  restSupplementDescription: string;
  restStimulating: string;
  restCafesPerDay: string;
  restTeasPerDay: string;
  restEnergyDrinksPerDay: string;
  restScreens: string;

  constructor(survey: SurveyCgmInitial) {
    this.id = survey.id;
    this.disease = survey.answers?.DISEASE ? survey.answers.DISEASE.toString() : '';
    this.diseaseDescription = survey.answers?.DISEASE_DESCRIPTION ? survey.answers.DISEASE_DESCRIPTION.split(',') : [];
    this.diseaseFamily = survey.answers?.DISEASE_FAMILY ? survey.answers.DISEASE_FAMILY.toString() : '';
    this.diseaseFamilyDescription = survey.answers?.DISEASE_FAMILY_DESCRIPTION
      ? survey.answers.DISEASE_FAMILY_DESCRIPTION.toString()
      : '';
    this.drug = survey.answers?.DRUG ? survey.answers.DRUG.toString() : '';
    this.drugDescription = survey.answers?.DRUG_DESCRIPTION ? survey.answers.DRUG_DESCRIPTION.toString() : '';
    this.age = survey.answers?.AGE ? survey.answers.AGE.toString() : '';
    this.birthDate = survey.answers?.BIRTH_DATE ? new Date(survey.answers.BIRTH_DATE) : '';
    this.gender = survey.answers?.GENDER ? survey.answers.GENDER.toString() : '';
    this.height = survey.answers?.HEIGHT ? survey.answers.HEIGHT.toString() : '';
    this.weight = survey.answers?.WEIGHT ? survey.answers.WEIGHT.toString() : '';
    this.bodyShape = survey.answers?.BODY_SHAPE ? survey.answers.BODY_SHAPE.toString() : '';
    this.absShape = survey.answers?.ABS_SHAPE ? survey.answers.ABS_SHAPE.toString() : '';
    this.complexion = survey.answers?.COMPLEXION ? survey.answers.COMPLEXION.toString() : '';
    this.fatPercentage = survey.answers?.FAT_PERCENTAGE ? survey.answers.FAT_PERCENTAGE.toString() : '';
    this.musclePercentage = survey.answers?.MUSCLE_PERCENTAGE ? survey.answers.MUSCLE_PERCENTAGE.toString() : '';
    this.healzObjectiveDescription = survey.answers?.HEALZ_OBJECTIVE_DESCRIPTION
      ? survey.answers.HEALZ_OBJECTIVE_DESCRIPTION.toString()
      : '';
    this.weightLoseQuantity = survey.answers?.WEIGHT_LOSE_QUANTITY
      ? survey.answers.WEIGHT_LOSE_QUANTITY.toString()
      : '';
    this.weightLoseDietTested = survey.answers?.WEIGHT_LOSE_DIET_TESTED
      ? survey.answers.WEIGHT_LOSE_DIET_TESTED.toString()
      : '';
    this.weightGainQuantity = survey.answers?.WEIGHT_GAIN_QUANTITY
      ? survey.answers.WEIGHT_GAIN_QUANTITY.toString()
      : '';
    this.weightGainDietTested = survey.answers?.WEIGHT_GAIN_DIET_TESTED
      ? survey.answers.WEIGHT_GAIN_DIET_TESTED.toString()
      : '';
    this.healzObjective = survey.answers?.HEALZ_OBJECTIVE ? survey.answers.HEALZ_OBJECTIVE.toString() : '';
    this.sport = survey.answers?.SPORT ? survey.answers.SPORT.toString() : '';
    this.sportHourPerDay = survey.answers?.SPORT_HOUR_PER_DAY ? survey.answers.SPORT_HOUR_PER_DAY.toString() : '';
    this.sportDayTime = survey.answers?.SPORT_DAY_TIME ? survey.answers.SPORT_DAY_TIME.toString() : '';
    this.sportStrengthDays = survey.answers?.SPORT_STRENGTH_DAYS ? survey.answers.SPORT_STRENGTH_DAYS.toString() : '';
    this.sportCardioDays = survey.answers?.SPORT_CARDIO_DAYS ? survey.answers.SPORT_CARDIO_DAYS.toString() : '';
    this.currentDiet = survey.answers?.CURRENT_DIET ? survey.answers.CURRENT_DIET.toString() : '';
    this.currentDietDescription = survey.answers?.CURRENT_DIET_DESCRIPTION
      ? survey.answers.CURRENT_DIET_DESCRIPTION.toString()
      : '';
    this.currentDietNumberOfMeals = survey.answers?.CURRENT_DIET_NUMBER_OF_MEALS
      ? survey.answers.CURRENT_DIET_NUMBER_OF_MEALS.toString()
      : '';
    this.currentDietSnack = survey.answers?.CURRENT_DIET_SNACK ? survey.answers.CURRENT_DIET_SNACK.toString() : '';
    this.currentDietSnackDescription = survey.answers?.CURRENT_DIET_SNACK_DESCRIPTION
      ? survey.answers.CURRENT_DIET_SNACK_DESCRIPTION.toString()
      : '';
    this.currentDietFast = survey.answers?.CURRENT_DIET_FAST ? survey.answers.CURRENT_DIET_FAST.toString() : '';
    this.currentDietFastHours = survey.answers?.CURRENT_DIET_FAST_HOURS
      ? survey.answers.CURRENT_DIET_FAST_HOURS.toString()
      : '';
    this.currentDietDigestiveProblems = survey.answers?.CURRENT_DIET_DIGESTIVE_PROBLEMS
      ? survey.answers.CURRENT_DIET_DIGESTIVE_PROBLEMS.toString()
      : '';
    this.currentDietDigestiveProblemsDescription = survey.answers?.CURRENT_DIET_DIGESTIVE_PROBLEMS_DESCRIPTION
      ? survey.answers.CURRENT_DIET_DIGESTIVE_PROBLEMS_DESCRIPTION.split(',')
      : [];
    this.currentDietSupplement = survey.answers?.CURRENT_DIET_SUPPLEMENT
      ? survey.answers.CURRENT_DIET_SUPPLEMENT.toString()
      : '';
    this.currentDietSupplementDescription = survey.answers?.CURRENT_DIET_SUPPLEMENT_DESCRIPTION
      ? survey.answers.CURRENT_DIET_SUPPLEMENT_DESCRIPTION.toString()
      : '';
    this.currentDietAllergy = survey.answers?.CURRENT_DIET_ALLERGY
      ? survey.answers.CURRENT_DIET_ALLERGY.toString()
      : '';
    this.currentDietAllergyDescription = survey.answers?.CURRENT_DIET_ALLERGY_DESCRIPTION
      ? survey.answers.CURRENT_DIET_ALLERGY_DESCRIPTION.toString()
      : '';
    this.currentDietWaterQuantity = survey.answers?.CURRENT_DIET_WATER_QUANTITY
      ? survey.answers.CURRENT_DIET_WATER_QUANTITY.toString()
      : '';
    this.dayToDay = survey.answers?.DAY_TO_DAY ? survey.answers.DAY_TO_DAY.toString() : '';
    this.dayToDayActivity = survey.answers?.DAY_TO_DAY_ACTIVITY ? survey.answers.DAY_TO_DAY_ACTIVITY.toString() : '';
    this.dayToDaySmoker = survey.answers?.DAY_TO_DAY_SMOKER ? survey.answers.DAY_TO_DAY_SMOKER.toString() : '';
    this.dayToDaySmokerQuantity = survey.answers?.DAY_TO_DAY_SMOKER_QUANTITY
      ? survey.answers.DAY_TO_DAY_SMOKER_QUANTITY.toString()
      : '';
    this.restHours = survey.answers?.REST_HOURS ? survey.answers.REST_HOURS.toString() : '';
    this.restWakeUpTired = survey.answers?.REST_WAKE_UP_TIRED ? survey.answers.REST_WAKE_UP_TIRED.toString() : '';
    this.restDayEnergy = survey.answers?.REST_DAY_ENERGY ? survey.answers.REST_DAY_ENERGY.toString() : '';
    this.restPattern = survey.answers?.REST_PATTERN ? survey.answers.REST_PATTERN.toString() : '';
    this.restSupplement = survey.answers?.REST_SUPPLEMENT ? survey.answers.REST_SUPPLEMENT.toString() : '';
    this.restSupplementDescription = survey.answers?.REST_SUPPLEMENT_DESCRIPTION
      ? survey.answers.REST_SUPPLEMENT_DESCRIPTION.toString()
      : '';
    this.restStimulating = survey.answers?.REST_STIMULATING ? survey.answers.REST_STIMULATING.toString() : '';
    this.restCafesPerDay = survey.answers?.REST_CAFES_PER_DAY ? survey.answers.REST_CAFES_PER_DAY.toString() : '';
    this.restTeasPerDay = survey.answers?.REST_TEAS_PER_DAY ? survey.answers.REST_TEAS_PER_DAY.toString() : '';
    this.restEnergyDrinksPerDay = survey.answers?.REST_ENERGY_DRINKS_PER_DAY
      ? survey.answers.REST_ENERGY_DRINKS_PER_DAY.toString()
      : '';
    this.restScreens = survey.answers?.REST_SCREENS ? survey.answers.REST_SCREENS.toString() : '';
  }

  toSurveyCgmInitial(): SurveyCgmInitial {
    return {
      id: this.id,
      answers: {
        DISEASE: this.disease ? this.disease : undefined,
        DISEASE_DESCRIPTION: this.diseaseDescription?.join(','),
        DISEASE_FAMILY: this.diseaseFamily,
        DISEASE_FAMILY_DESCRIPTION: this.diseaseFamilyDescription,
        DRUG: this.drug,
        DRUG_DESCRIPTION: this.drugDescription,
        AGE: this.age ? +this.age : undefined,
        BIRTH_DATE: this.birthDate ? this.birthDate : undefined,
        GENDER: this.gender as Genders,
        HEIGHT: this.height ? +this.height : undefined,
        WEIGHT: this.weight ? +this.weight : undefined,
        BODY_SHAPE: this.bodyShape as BodyShapes,
        ABS_SHAPE: this.absShape as AbsShapes,
        COMPLEXION: this.complexion as Complexion,
        FAT_PERCENTAGE: this.fatPercentage ? +this.fatPercentage : undefined,
        MUSCLE_PERCENTAGE: this.musclePercentage ? +this.musclePercentage : undefined,
        HEALZ_OBJECTIVE_DESCRIPTION: this.healzObjectiveDescription as Objectives,
        WEIGHT_LOSE_QUANTITY: this.weightLoseQuantity as WeightQuantity,
        WEIGHT_LOSE_DIET_TESTED: this.weightLoseDietTested,
        WEIGHT_GAIN_QUANTITY: this.weightGainQuantity as WeightQuantity,
        WEIGHT_GAIN_DIET_TESTED: this.weightGainDietTested,
        HEALZ_OBJECTIVE: this.healzObjective,
        SPORT: this.sport,
        SPORT_HOUR_PER_DAY: this.sportHourPerDay as SportHourPerDay,
        SPORT_DAY_TIME: this.sportDayTime,
        SPORT_STRENGTH_DAYS: this.sportStrengthDays as SportStrengthDays,
        SPORT_CARDIO_DAYS: this.sportCardioDays as SportCardioDays,
        CURRENT_DIET: this.currentDiet,
        CURRENT_DIET_DESCRIPTION: this.currentDietDescription,
        CURRENT_DIET_NUMBER_OF_MEALS: this.currentDietNumberOfMeals as NumberOfMeals,
        CURRENT_DIET_SNACK: this.currentDietSnack,
        CURRENT_DIET_SNACK_DESCRIPTION: this.currentDietSnackDescription as DietSnacks,
        CURRENT_DIET_FAST: this.currentDietFast,
        CURRENT_DIET_FAST_HOURS: this.currentDietFastHours as FastHours,
        CURRENT_DIET_DIGESTIVE_PROBLEMS: this.currentDietDigestiveProblems,
        CURRENT_DIET_DIGESTIVE_PROBLEMS_DESCRIPTION: this.currentDietDigestiveProblemsDescription?.join(','),
        CURRENT_DIET_SUPPLEMENT: this.currentDietSupplement,
        CURRENT_DIET_SUPPLEMENT_DESCRIPTION: this.currentDietSupplementDescription,
        CURRENT_DIET_ALLERGY: this.currentDietAllergy,
        CURRENT_DIET_ALLERGY_DESCRIPTION: this.currentDietAllergyDescription,
        CURRENT_DIET_WATER_QUANTITY: this.currentDietWaterQuantity as WaterQuantity,
        DAY_TO_DAY: this.dayToDay as DayToDay,
        DAY_TO_DAY_ACTIVITY: this.dayToDayActivity as DayToDayActivity,
        DAY_TO_DAY_SMOKER: this.dayToDaySmoker,
        DAY_TO_DAY_SMOKER_QUANTITY: this.dayToDaySmokerQuantity as DayToDaySmoke,
        REST_HOURS: this.restHours as RestHours,
        REST_WAKE_UP_TIRED: this.restWakeUpTired,
        REST_DAY_ENERGY: this.restDayEnergy as RestDayEnergy,
        REST_PATTERN: this.restPattern as RestPatterns,
        REST_SUPPLEMENT: this.restSupplement,
        REST_SUPPLEMENT_DESCRIPTION: this.restSupplementDescription,
        REST_STIMULATING: this.restStimulating,
        REST_CAFES_PER_DAY: this.restCafesPerDay as DrinksPerDay,
        REST_TEAS_PER_DAY: this.restTeasPerDay as DrinksPerDay,
        REST_ENERGY_DRINKS_PER_DAY: this.restEnergyDrinksPerDay as DrinksPerDay,
        REST_SCREENS: this.restScreens,
      },
    };
  }
}

interface SurveyCgmInitial {
  id: SurveyType;
  answers: AnswersCgmInitial;
}

interface AnswersCgmInitial {
  DISEASE: string | undefined;
  DISEASE_DESCRIPTION: string | undefined;
  DISEASE_FAMILY: string | undefined;
  DISEASE_FAMILY_DESCRIPTION: string | undefined;
  DRUG: string | undefined;
  DRUG_DESCRIPTION: string | undefined;
  GENDER: Genders | undefined;
  AGE: number | undefined;
  BIRTH_DATE: string | undefined;
  HEIGHT: number | undefined;
  WEIGHT: number | undefined;
  BODY_SHAPE: BodyShapes | undefined;
  ABS_SHAPE: AbsShapes | undefined;
  COMPLEXION: Complexion | undefined;
  FAT_PERCENTAGE: number | undefined;
  MUSCLE_PERCENTAGE: number | undefined;
  HEALZ_OBJECTIVE_DESCRIPTION: Objectives | undefined;
  WEIGHT_LOSE_QUANTITY: WeightQuantity | undefined;
  WEIGHT_LOSE_DIET_TESTED: string | undefined;
  WEIGHT_GAIN_QUANTITY: WeightQuantity | undefined;
  WEIGHT_GAIN_DIET_TESTED: string | undefined;
  HEALZ_OBJECTIVE: string | undefined;
  SPORT: string | undefined;
  SPORT_STRENGTH_DAYS: SportStrengthDays | undefined;
  SPORT_CARDIO_DAYS: SportCardioDays | undefined;
  SPORT_DAY_TIME: string | undefined;
  SPORT_HOUR_PER_DAY: SportHourPerDay | undefined;
  CURRENT_DIET: string | undefined;
  CURRENT_DIET_DESCRIPTION: string | undefined;
  CURRENT_DIET_NUMBER_OF_MEALS: NumberOfMeals | undefined;
  CURRENT_DIET_SNACK: string | undefined;
  CURRENT_DIET_SNACK_DESCRIPTION: DietSnacks | undefined;
  CURRENT_DIET_FAST: string | undefined;
  CURRENT_DIET_FAST_HOURS: FastHours | undefined;
  CURRENT_DIET_DIGESTIVE_PROBLEMS: string | undefined;
  CURRENT_DIET_DIGESTIVE_PROBLEMS_DESCRIPTION: string | undefined;
  CURRENT_DIET_SUPPLEMENT: string | undefined;
  CURRENT_DIET_SUPPLEMENT_DESCRIPTION: string | undefined;
  CURRENT_DIET_ALLERGY: string | undefined;
  CURRENT_DIET_ALLERGY_DESCRIPTION: string | undefined;
  CURRENT_DIET_WATER_QUANTITY: WaterQuantity | undefined;
  DAY_TO_DAY: DayToDay | undefined;
  DAY_TO_DAY_ACTIVITY: DayToDayActivity | undefined;
  DAY_TO_DAY_SMOKER: string | undefined;
  DAY_TO_DAY_SMOKER_QUANTITY: DayToDaySmoke | undefined;
  REST_HOURS: RestHours | undefined;
  REST_WAKE_UP_TIRED: string | undefined;
  REST_DAY_ENERGY: RestDayEnergy | undefined;
  REST_PATTERN: RestPatterns | undefined;
  REST_SUPPLEMENT: string | undefined;
  REST_SUPPLEMENT_DESCRIPTION: string | undefined;
  REST_STIMULATING: string | undefined;
  REST_CAFES_PER_DAY: DrinksPerDay | undefined;
  REST_TEAS_PER_DAY: DrinksPerDay | undefined;
  REST_ENERGY_DRINKS_PER_DAY: DrinksPerDay | undefined;
  REST_SCREENS: string | undefined;
}

export {
  Survey,
  SurveyType,
  BodyShapes,
  Diseases,
  AbsShapes,
  Complexion,
  Objectives,
  WeightQuantity,
  SportStrengthDays,
  SportCardioDays,
  SportHourPerDay,
  Diets,
  NumberOfMeals,
  DietSnacks,
  FastHours,
  DigestiveProblems,
  WaterQuantity,
  DayToDay,
  DayToDayActivity,
  DayToDaySmoke,
  RestHours,
  RestDayEnergy,
  RestPatterns,
  DrinksPerDay,
  SurveyCgmInitial,
  SurveyCgmInitialFormDto,
  AnswersCgmInitial,
};
